<template>
  <div>
    <!-- Show a table -->
    <v-simple-table>
      <!-- Show the table head -->
      <template v-slot:default>
        <tbody>
          <!-- The header row -->
          <tr>
            <!-- First cell is to be empty -->
            <td class="pa-3"></td>

            <!-- Loop through the columns -->
            <td
              v-for="(influencer, influencerIndex) in influencers"
              :key="`header-column-${influencerIndex}`"
              class="pa-3 text-center"
            >
              <!-- Show the profile avatars -->
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span class="d-flex justify-center">
                    <profile-chip
                      :data="influencer.preview"
                      v-on="on"
                      v-bind="attrs"
                      avatar-only
                    />
                  </span>
                </template>

                <!-- Show the profile chip -->
                <span class="py-2">
                  <profile-chip :data="influencer.preview" />
                </span>
              </v-tooltip>
            </td>
          </tr>

          <!-- Loop through the rows -->
          <tr
            v-for="(row, rowIndex) in influencers"
            :key="'row' + rowIndex"
          >
            <td class="pa-3 text-center">
              <!-- Show the preview here -->
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <span class="d-flex justify-center">
                    <profile-chip
                      :data="row.preview"
                      v-on="on"
                      v-bind="attrs"
                      avatar-only
                    />
                  </span>
                </template>

                <!-- Show the profile chip -->
                <span class="py-2">
                  <profile-chip :data="row.preview" />
                </span>
              </v-tooltip>
            </td>

            <!-- Loop through the columns -->
            <td
              v-for="(column, columnIndex) in influencers"
              :key="`row-${rowIndex}-column-${columnIndex}`"
              class="pa-3"
            >
              <!-- If it's the diagonal cell -->
              <template v-if="rowIndex === columnIndex">
                <!-- Show the empty cell -->
                <div class="d-flex justify-center">
                  <span
                    class="pa-3 rounded heat-map-cell heat-map-cell--diagonal"
                  ></span>
                </div>
              </template>
              <!-- Otherwise -->
              <template v-else>
                <div class="d-flex justify-center text-center">
                  <!-- Show the count -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-on="on"
                        v-bind="attrs"
                        class="pa-3 rounded heat-map-cell white-space-nowrap font-weight-bold"
                        :style="computeColor(row, column)"
                      >
                        {{ fPercent(100 * computeOverlap(row, column) / row.data.followers) }}
                      </span>
                    </template>

                    <div class="text-center">
                      <div>
                        Common followers: {{ nFormatter(Math.round(computeOverlap(row, column))) }}
                      </div>

                      <div v-if="(row.preview.fullname || row.preview.username || null) && (column.preview.fullname || column.preview.username || null)">
                        {{ row.preview.fullname || row.preview.username || ''}} & {{ column.preview.fullname || column.preview.username || ''}}
                      </div>
                    </div>
                  </v-tooltip>
                </div>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
// Import children components
const UserAvatar = () => import(/* webpackChunkName: "user-avatar" */ "@/blocks/common/UserAvatar")
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ "@/blocks/common/ProfileChip")

// Export the SFC
export default {
  // Name of the component
  name: "HeatMap",

  // Register children components
  components: {
    UserAvatar,
    ProfileChip
  },

  // Accept incoming props
  props: {
    influencers: Array
  },

  // Define local method functions
  methods: {
    /**
     * Compute the overlap between two influencers
     *
     * @param {Object} influencer1 The first influencer
     * @param {Object} influencer2 The second influencer
     * @returns {Number} The overlap count
     */
    computeOverlap(influencer1, influencer2) {
      // Compute the overlapping followers count
      const overlappingFollowers1 = (influencer1.data.overlapping_percentage * influencer1.data.followers)
      const overlappingFollowers2 = (influencer2.data.overlapping_percentage * influencer2.data.followers)

      // Get the overlapping followers
      const overlappingFollowers = Math.min(overlappingFollowers1, overlappingFollowers2)

      // Return the value
      return overlappingFollowers
    },

    /**
     * Compute the color of the cell
     *
     * @param {Object} influencer1 The first influencer
     * @param {Object} influencer2 The second influencer
     * @returns {Object} The computed color
     */
    computeColor(influencer1, influencer2) {
      // Get the overlapping followers
      const overlappingFollowers = this.computeOverlap(influencer1, influencer2)
      // Get the percentage value
      const percentage = overlappingFollowers / influencer1.data.followers
      // Get the difference
      const alpha = percentage * 3

      // Return the color
      return {
        "background-color": colors.rgbToRgba(colors.hexToRgb(host.theme.color.primary), alpha),
        "color": alpha < 0.5 ? "black" : "white",
      }
    }
  }
}
</script>

<style lang="stylus">
.heat-map-cell
  display block
  width 80px
  height 45px

  &--diagonal
    border 2px solid #e5e5e5
    background-color #f9f9f9
</style>
