var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{staticClass:"pa-3"}),_vm._l((_vm.influencers),function(influencer,influencerIndex){return _c('td',{key:("header-column-" + influencerIndex),staticClass:"pa-3 text-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',{staticClass:"d-flex justify-center"},[_c('profile-chip',_vm._g(_vm._b({attrs:{"data":influencer.preview,"avatar-only":""}},'profile-chip',attrs,false),on))],1)]}}],null,true)},[_c('span',{staticClass:"py-2"},[_c('profile-chip',{attrs:{"data":influencer.preview}})],1)])],1)})],2),_vm._l((_vm.influencers),function(row,rowIndex){return _c('tr',{key:'row' + rowIndex},[_c('td',{staticClass:"pa-3 text-center"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',{staticClass:"d-flex justify-center"},[_c('profile-chip',_vm._g(_vm._b({attrs:{"data":row.preview,"avatar-only":""}},'profile-chip',attrs,false),on))],1)]}}],null,true)},[_c('span',{staticClass:"py-2"},[_c('profile-chip',{attrs:{"data":row.preview}})],1)])],1),_vm._l((_vm.influencers),function(column,columnIndex){return _c('td',{key:("row-" + rowIndex + "-column-" + columnIndex),staticClass:"pa-3"},[(rowIndex === columnIndex)?[_c('div',{staticClass:"d-flex justify-center"},[_c('span',{staticClass:"pa-3 rounded heat-map-cell heat-map-cell--diagonal"})])]:[_c('div',{staticClass:"d-flex justify-center text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"pa-3 rounded heat-map-cell white-space-nowrap font-weight-bold",style:(_vm.computeColor(row, column))},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.fPercent(100 * _vm.computeOverlap(row, column) / row.data.followers))+" ")])]}}],null,true)},[_c('div',{staticClass:"text-center"},[_c('div',[_vm._v(" Common followers: "+_vm._s(_vm.nFormatter(Math.round(_vm.computeOverlap(row, column))))+" ")]),((row.preview.fullname || row.preview.username || null) && (column.preview.fullname || column.preview.username || null))?_c('div',[_vm._v(" "+_vm._s(row.preview.fullname || row.preview.username || '')+" & "+_vm._s(column.preview.fullname || column.preview.username || '')+" ")]):_vm._e()])])],1)]],2)})],2)})],2)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }